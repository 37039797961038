const styles = require("@fortawesome/fontawesome-svg-core/styles.css")
const config = require("@fortawesome/fontawesome-svg-core");

config.autoAddCss = false

const anchorScroll = (location) => {
  if (location && location.hash) {
    window.scrollTo({
      top: document.getElementById(location.hash.replace("#", "")).offsetTop
    })
  }
}

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {

  const currentPosition = getSavedScrollPosition(location)

  if (location.hash !== "") {
    anchorScroll(location)
    return true
  }
  if (!location.pathname.includes("/voter-resources/register-to-vote") && !location.pathname.includes("/education/us-representation") && !location.pathname.includes("/education/ms-representation")) {
    window.scrollTo(...(currentPosition || [0, 0]))
  }

  return false
}
